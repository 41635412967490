:root {
  --black: #505050;
  --dark-gray: #878787;
  --light-gray: #C0C0C0;
  --white: #e7e7e7;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}

body {
  overflow: hidden;
}

.active {
  display: flex!important;
}

.minimize {
  transform: scale(0)!important;
}

.front {
  z-index: 2;
}

.main {
  width: 100%;
  height: 100vh;
  background-color: #008080;
  display: flex;
  flex-direction: column;
}

.desktop {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) calc(100% - 10px);
  background-size: calc(10% + 100px) ;
}

/*----Loading----*/

.loading {
  background-color: #0c0c0c;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 90vmin;
  max-width: 500px;
  height: auto;
}

.loadText {
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px
}

.logo_path{
  transition: 2.5s;
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
}

/*----File----*/

.fileContainer {
  width: 70px;
  height: 85px;
  margin: 10px;
}

.file {
  user-select: none;
  width: 70px;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.fileIcon {
  width: 50px;
  height: 50px;
}

.fileName {
  margin-top: 3px;
  font-size: 14px;
  user-select: none;
  text-align: center;
  color: white;
}

/*----AppBar----*/

.startMenu {
  display: flex;
  position: absolute;
  max-width: 95%;
  width: 300px;
  max-height: calc(100vh - 50px);
  height: 400px;
  background-color: var(--light-gray);
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  transform: translateY(calc(-50% - 25px));
}

.startMenuTitleContainer {
  background: linear-gradient(0deg,darkblue 20%, black 100%);
  padding: 5px;
  display: flex;
  align-items: flex-end;
}

.startMenuTitle {
  width:20px;
  height: auto;
}

.startMenuContent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.startMenuFile {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.startMenuFile:hover {
  background-color: #2e2ea1;
  color: white;
}

.menuFileIcon {
  width: 40px;
  height: 40px;
}

.menuFileName {
  font-size: 16px;
  padding: 0 4px;
}

.menuDivider {
  margin: auto 4px 0 4px;
  height: 4px;
  border-top: 2px solid var(--dark-gray);
  border-bottom: 2px solid var(--white);
}

.appBar {
  background-color: var(--light-gray);
  width: 100%;
  min-height: 50px;
  height: auto;
  margin-top: auto;
  border-top: 3px solid #e7e7e7;
  display: flex;
  align-items: center;
  z-index: 1000;
}

.startButton {
  width: 90px;
  background-color: var(--light-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-weight: 600;
  font-size: 16px;
  margin: 5px;
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  outline:none;
  user-select: none;
}

.startButton:active {
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.startLogo {
  height: 23px;
  width: auto;
}

.startText {
  letter-spacing: 0.05em;
}

@media screen and (max-width: 400px) {
  .startText {
    display: none;
  }
}

.divider {
  background-color: var(--white);
  height: 80%;
  width: 4px;
  border-left: 2px solid var(--dark-gray);
  border-right: 2px solid var(--white);
}

.utility {
  user-select: none;
  margin: 5px;
  border-top: 2px solid var(--dark-gray);
  border-left: 2px solid var(--dark-gray);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
  display: flex;
  padding: 5px 3px;
  align-items: center;
  display: flex;
}

.utilityVolume {
  height: 23px;
  width: 23px;
}

.volumeSlider {
  position: absolute;
  width: 80px;
  height: 150px;
  background-color: var(--light-gray);
  transform: translate(-30px, -105px);
  border-bottom: 2px solid var(--dark-gray);
  border-right: 2px solid var(--dark-gray);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  padding: 5px;
  flex-direction: column;
  display: none;
  align-items: center;
}

.sliderContainer {
  height: 100%;
  margin: 10px 0;
  width: 15px;
  display: flex;
  justify-content: center;
}

.sliderThumb {
  height: 15px;
  width: 25px;
  background-color: var(--light-gray);
  border-bottom: 2px solid var(--dark-gray);
  border-right: 2px solid var(--dark-gray);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  outline: none;
}

.sliderTrack {
  width: 100%;
  background-color: var(--black);
  border-top: 2px solid var(--dark-gray);
  border-left: 2px solid var(--dark-gray);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
  width: 6px;
}

.mute {
  display: flex;
  align-items: center;
}

.checkbox {
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  background-color: white;
  margin-right: 5px;
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.checkbox span {
  display: none;
}

.utilityTime {
  font-size: 16px;
  margin-left: auto;
  margin-right: 2px;
  margin-left: 4px;
}

.appBarFileContainer {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.appBarFile {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 5px 0 5px 5px;
  padding: 3px;
  flex: 1;
  max-width: 150px;
  min-width: 40px;
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
}

.appBarFile:active {
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.appBarFile:last-of-type {
  margin-right: 5px;
}

.appBarFileIcon {
  width: 30px;
  height: 30px;
  margin-right: 3px;
}

.appBarFileName {
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*----About----*/

.about {
  transition: .3s;
  position: absolute;
  width: 100%;
  height: calc(100vh - 50px);
  padding: 3px;
  background-color: var(--light-gray);
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  display: flex;
  flex-direction: column;
  animation: openPage .3s;
}

@keyframes openPage {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.pageTitle {
  user-select: none;
  display: flex;
  height: 32px;
  align-items: center;
  background-color:darkblue;
  background: linear-gradient(90deg,darkblue 10%, lightblue 100%);
  font-size: 15px;
  font-weight: 600;
  color: white;
  padding: 3px;
}

.pageTitleIcon {
  height: 100%;
  margin-right: 3px;
}

.pageTitleText {
  flex:1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pageButton {
  color: black;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 30px;
  background-color: var(--light-gray);
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  margin-left: 2px;
}

.pageButton:active {
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.under {
  margin-bottom: 23px;
  font-size: 34px;
}

.square {
  margin-top: -48px;
  font-weight: 600;
  font-size: 65px;
  height: 100%;
}

.cross {
  margin-right: 1px;
  margin-bottom: 2px;
  font-size: 20px;
}

.addressLineContainer {
  margin-top: 2px;
  margin-bottom: 3px;
  border-top: 2px solid var(--dark-gray);
  border-left: 2px solid var(--dark-gray);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.addressLine {
  user-select: none;
  padding: 1px 8px;
  font-size: 13px;
  height: 28px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--dark-gray);
  border-right: 2px solid var(--dark-gray);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
}

.address {
  flex: 1;
  margin: 0 5px 0 8px;
  display: flex;
  align-items: flex-end;
  height: 100%;
  background-color: white;
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.address img {
  padding: 1px 2px;
  height: 100%;
}

.addressArrow {
  width: 20px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-gray);
  margin-left: auto;
  font-size: 10px;
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
}

.pageContainer {
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgb(156 55 100) 0%, rgb(38 50 116) 100%);
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
  display: flex;
  flex-direction: column;
  color: var(--white);
  overflow-y: auto;
}

.pageContainer * {
  font-family: monospace;
}

.pageHeader {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  text-align: center;
  align-self: stretch;
  margin-bottom: 30px;
}

.pageHeader h1 {
  margin-top: 10px;
  font-size: 50px;
}

.pageHeader h2 {
  font-size: 25px;
}

.pageContent {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: calc(50% - 300px) calc(100% + 15px);
  background-size: 80px;
}

.ufoContainer {
  overflow-x: hidden;
}

.pageUfo {
  animation: moveUfo 8s linear infinite;
  width: 95px;
  height: auto;
}

@keyframes moveUfo {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100vw);
  }
}

.pageInfo {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-position: calc(50% + 230px) 80px, calc(50% - 230px) 80px;
  background-size: auto 100px;
}

@media screen and (max-width: 600px) {
  .pageInfo {
    background-image: none !important;
  }
}

.infoPic {
  width: 50vmin;
  max-width: 200px;
  height: auto;
  margin: 15px;
}

.infoDescription {
  text-align: center;
  font-size: 25px;
  max-width: 800px;
  margin: 15px;
}

@media screen and (max-width: 400px) {
  .infoDescription {
    margin: 0;
  }
}

.pageTechnologies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0;
  background-repeat: no-repeat;
  background-position: calc(50% - 400px) 40%, calc(50% + 400px) 90%;
}

.techContainer {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.techTitle {
  text-align: center;
  font-size: 30px;
  margin: 10px;
}

.techBorder {
  padding: 2px;
  background-color: var(--light-gray);
  border-bottom: 2px solid var(--dark-gray);
  border-right: 2px solid var(--dark-gray);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
}

.techContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
  border-top: 2px solid var(--dark-gray);
  border-left: 2px solid var(--dark-gray);

}

.techContent .column {
  display: flex;
  flex-direction: column;
  width: 190px;
}

.techContent span{
  padding: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.techContent img{
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.educationContainer {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.educationTitle {
  text-align: center;
  font-size: 30px;
  margin: 10px;
}

.education {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 15px;
  list-style: none;
}

.educationName {
  font-size: 23px;
  font-weight: 800;
  margin-bottom: 2px;
}

.educationCourse {
  font-size: 20px;
}

.educationYear {
  font-weight: 600;
}

.hobbiesContainer {
  margin: 10px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hobbiesTitle {
  font-size: 30px;
  text-align: center;
}

.hobbiesContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.hobbiesColumn {
  padding: 10px;
}

.hobbiesColumnTitle {
  font-size: 23px;
  font-weight: 600;
}

.hobbies {
  font-size: 20px;
  padding-left: 10px;
}

footer {
  width: 100%;
  height: 40px;
}

/*----Portfolio----*/

.portfolio {
  transition: .3s;
  position: absolute;
  width: 100%;
  height: calc(100vh - 50px);
  padding: 3px;
  background-color: var(--light-gray);
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  display: flex;
  flex-direction: column;
  animation: openPage .3s;
}

.folderContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  color: var(--white);
  overflow-y: auto;
  padding: 2px;
}

.optionsLine {
  display: flex;
  padding: 0 3px;
}

.optionsLine span{
  padding: 5px;
  cursor: default;
}

.selectFile {
  width: auto;
  height: 100%;
  background-color: gray;
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
  overflow-y: auto;
}

.folderFile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  height: 85px;
  width: 80px;
  border: 2px solid transparent;
}

.folderFileSelect {
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.folderFileIcon {
  width: 40px;
  height: 40px;
  margin-bottom: 3px;
}

.folderFileName {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 13px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.selectDisplay {
  background-color: white;
  flex: 1;
  height: 100%;
  width: 100%;
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}


@media screen and (max-width: 500px) {
  .folderContainer {
    flex-direction: column;
  }

  .selectFile {
    flex-direction: row;
    height: auto;
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }

  .selectDisplay {
    height: 0;
  }
}

.portfolioDisplay {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;
}

.displayTitle {
  margin-bottom: 15px;
  text-align: center;
}

.displayImages {
  width: 100%;
  max-width: 350px;
  box-shadow: 0 0 5px 3px #00000033;
  overflow-y: hidden;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #000000;
}

.imageButtonsContainer {
  display: flex;
  justify-content: stretch;
  width: 350px;
  max-width: 100%;
  margin-bottom: 15px;
}

.imageButton {
  padding: 2px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  outline:none;
  user-select: none;
  flex: 1;
}

.imageButton:active {
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.displayWebContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.displayWeb {
  margin: 5px;
  padding: 3px;
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
}

.displayWeb:active {
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.displayText {
  max-width: 800px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.displaySubTitle {
  margin-top: 20px;
  margin-bottom: 5px;
}

.displayTechStack {
  font-size: 18px;
}

.displayTechStack li {
  padding: 2px;
}

/*----Contact----*/

.contact {
  transition: .3s;
  position: absolute;
  width: 100%;
  height: calc(100vh - 50px);
  padding: 3px;
  background-color: var(--light-gray);
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
  display: flex;
  flex-direction: column;
  animation: openPage .3s;
}

.contactContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  overflow-y: auto;
  border-top: 2px solid var(--black);
  border-left: 2px solid var(--black);
  border-bottom: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

.contactContent {
  width: 100%;
  overflow-x: auto;
}

.contactTable {
  width: 100%;
  border-spacing: 0;
}

.tableHeader {
  padding: 3px;
  background-color: var(--light-gray);
  border-bottom: 2px solid var(--black);
  border-right: 2px solid var(--black);
  border-top: 2px solid var(--white);
  border-left: 2px solid var(--white);
}

.tableCell {
  padding: 3px;
  border-bottom: 1px solid black;
  background-color: white;
}

.tableCell a {
  color: darkblue;
  display: flex;
}

.tableCell:first-of-type {
  border-right: 1px solid black;
}

.at {
  height: 16px;
  width: auto;
  margin-top: auto;
}

.contactEmpty {
  width: 100%;
  flex: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200px;
}

/*----ShutDwn----*/

.shutdown {
  height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shutdownLogo {
  max-width: 400px;
  width: 95%;
}

.shutdownText {
  font-family: monospace;
  padding: 10px;
  font-size: 30px;
  color: white;
  text-align: center;
}

.shutdownText::after {
  content: '';
  animation: dots 1.5s linear infinite;
}

@keyframes dots {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}

.shutdownStart {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

@keyframes flicker {
  0% {
    opacity: 0.27861;
  }
  5% {
    opacity: 0.34769;
  }
  10% {
    opacity: 0.23604;
  }
  15% {
    opacity: 0.90626;
  }
  20% {
    opacity: 0.18128;
  }
  25% {
    opacity: 0.83891;
  }
  30% {
    opacity: 0.65583;
  }
  35% {
    opacity: 0.67807;
  }
  40% {
    opacity: 0.26559;
  }
  45% {
    opacity: 0.84693;
  }
  50% {
    opacity: 0.96019;
  }
  55% {
    opacity: 0.08594;
  }
  60% {
    opacity: 0.20313;
  }
  65% {
    opacity: 0.71988;
  }
  70% {
    opacity: 0.53455;
  }
  75% {
    opacity: 0.37288;
  }
  80% {
    opacity: 0.71428;
  }
  85% {
    opacity: 0.70419;
  }
  90% {
    opacity: 0.7003;
  }
  95% {
    opacity: 0.36108;
  }
  100% {
    opacity: 0.24387;
  }
}
.crt::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: flicker 2s infinite;
}
.crt::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%);
  background-size: 100% 2px;
  background-repeat: repeat;
  pointer-events: none;
  height: 100vh;
}
.crt {
  line-height: 1;
  text-shadow: 0.06rem 0 0.06rem #ea36af, -0.125rem 0 0.06rem #75fa69;
  letter-spacing: 0.125em;
  animation: .5s textflicker infinite alternate;
}

@keyframes textflicker {
from {
text-shadow: 1px 0 0 #ea36af, -2px 0 0 #75fa69;
}
to {
text-shadow: 2px 0.5px 2px #ea36af, -1px -0.5px 2px #75fa69;
}
}

.glitch {
  color: white;
  position: relative;
  margin: 0 auto;
}

@keyframes noise-anim {
  0% {
    clip: rect(42px, 9999px, 88px, 0);
  }
  5% {
    clip: rect(17px, 9999px, 27px, 0);
  }
  10% {
    clip: rect(48px, 9999px, 96px, 0);
  }
  15% {
    clip: rect(8px, 9999px, 2px, 0);
  }
  20% {
    clip: rect(32px, 9999px, 48px, 0);
  }
  25% {
    clip: rect(42px, 9999px, 37px, 0);
  }
  30% {
    clip: rect(34px, 9999px, 54px, 0);
  }
  35% {
    clip: rect(26px, 9999px, 30px, 0);
  }
  40% {
    clip: rect(48px, 9999px, 9px, 0);
  }
  45% {
    clip: rect(10px, 9999px, 34px, 0);
  }
  50% {
    clip: rect(13px, 9999px, 12px, 0);
  }
  55% {
    clip: rect(70px, 9999px, 73px, 0);
  }
  60% {
    clip: rect(95px, 9999px, 44px, 0);
  }
  65% {
    clip: rect(30px, 9999px, 19px, 0);
  }
  70% {
    clip: rect(80px, 9999px, 25px, 0);
  }
  75% {
    clip: rect(38px, 9999px, 100px, 0);
  }
  80% {
    clip: rect(59px, 9999px, 74px, 0);
  }
  85% {
    clip: rect(71px, 9999px, 32px, 0);
  }
  90% {
    clip: rect(44px, 9999px, 3px, 0);
  }
  95% {
    clip: rect(54px, 9999px, 89px, 0);
  }
  100% {
    clip: rect(11px, 9999px, 32px, 0);
  }
}
.glitch:after {
  width: 100%;
  height: 100%;
  content: 'Nikos Cocci';
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: white;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
  0% {
    clip: rect(2px, 9999px, 90px, 0);
  }
  5% {
    clip: rect(63px, 9999px, 27px, 0);
  }
  10% {
    clip: rect(52px, 9999px, 51px, 0);
  }
  15% {
    clip: rect(26px, 9999px, 70px, 0);
  }
  20% {
    clip: rect(9px, 9999px, 14px, 0);
  }
  25% {
    clip: rect(76px, 9999px, 1px, 0);
  }
  30% {
    clip: rect(46px, 9999px, 94px, 0);
  }
  35% {
    clip: rect(76px, 9999px, 46px, 0);
  }
  40% {
    clip: rect(36px, 9999px, 46px, 0);
  }
  45% {
    clip: rect(70px, 9999px, 96px, 0);
  }
  50% {
    clip: rect(74px, 9999px, 65px, 0);
  }
  55% {
    clip: rect(90px, 9999px, 65px, 0);
  }
  60% {
    clip: rect(34px, 9999px, 75px, 0);
  }
  65% {
    clip: rect(52px, 9999px, 56px, 0);
  }
  70% {
    clip: rect(49px, 9999px, 54px, 0);
  }
  75% {
    clip: rect(68px, 9999px, 26px, 0);
  }
  80% {
    clip: rect(55px, 9999px, 28px, 0);
  }
  85% {
    clip: rect(92px, 9999px, 35px, 0);
  }
  90% {
    clip: rect(32px, 9999px, 77px, 0);
  }
  95% {
    clip: rect(77px, 9999px, 11px, 0);
  }
  100% {
    clip: rect(81px, 9999px, 99px, 0);
  }
}
.glitch:before {
  width: 100%;
  height: 100%;
  content: 'Nikos Cocci';
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: white;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}

.absolute {
  display: none;
  position: absolute;
  z-index: 10;
  opacity: .5;
  pointer-events: none;
  animation: shift 10s infinite alternate;
}

@keyframes shift {
  0% {
    transform: translateX(0);
  }
  24% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(3px);
  }
  26% {
    transform: translateX(0);
  }
  49% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(2px);
  }
  51% {
    transform: translateX(0);
  }
  74% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-3px);
  }
  76% {
    transform: translateX(0px);
  }
  99% {
    transform: translateX(1px);
  }
}